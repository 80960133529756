import { Navigate } from "react-router-dom";
import { AppRoutesWithChildrenProps } from "./routeBuilder";
import Dashboard from "../pages/AppPages/Dashboard";
import Profile from "../pages/AppPages/Dashboard/Profile";
import DtcAccount from "../pages/AppPages/Dashboard/Account";
import Account from "../pages/AppPages/Dashboard/Account";
import AccountEdit from "../pages/AppPages/Dashboard/Account/edit";
import Address from "../pages/AppPages/Dashboard/Address/AddressList";
import EditAddress from "../pages/AppPages/Dashboard/Address/EditAddress";
import SyncDebugging from "../pages/AppPages/Dashboard/Debugging/SyncDebugging";
import EditCard from "../pages/AppPages/Dashboard/Wallet/EditCard";
import WalletList from "../pages/AppPages/Dashboard/Wallet/WalletList";
import PurchaseDetail from "../pages/AppPages/Dashboard/Purchase/PurchaseDetail";
import PurchaseList from "../pages/AppPages/Dashboard/Purchase/purchaseList";
import Activity from "../pages/AppPages/Dashboard/Rewards/Activity";
import CashOut from "../pages/AppPages/Dashboard/Rewards/Balance";
import Expiring from "../pages/AppPages/Dashboard/Rewards/Expiring";
import PayoutDetail from "../pages/AppPages/Dashboard/Rewards/PayoutDetail";
import Payouts from "../pages/AppPages/Dashboard/Rewards/Payouts";
import DashboardOutlet from "../pages/AppPages/Dashboard/DashboardOutlet";
import DtcDashboardOutlet from "../pages/AppPages/Dashboard/DashboardOutlet";
import DtcRewardsOutlet from "../pages/AppPages/Dashboard/Rewards/DtcRewardsOutlet";

const dashboardWalletRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "",
    index: true,
    element: <WalletList />,
    pageType: "Wallet",
    pageId: "Home",
  },
  {
    path: "new",
    index: true,
    element: <EditCard mode={"new"} />,
    pageType: "Wallet",
    pageId: "New",
  },
  {
    path: ":cardId",
    index: true,
    element: <EditCard mode={"edit"} />,
    pageType: "Wallet",
    pageId: "Edit",
  },
];

const dashboardAddressRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "",
    index: true,
    element: <Address />,
    pageType: "Address",
    pageId: "Home",
  },
  {
    path: "new",
    index: true,
    element: <EditAddress mode="new" />,
    pageType: "Address",
    pageId: "New",
  },
  {
    path: ":addressId",
    index: true,
    element: <EditAddress mode="edit" />,
    pageType: "Address",
    pageId: "Edit",
  },
];

const dashboardDebuggingRoutes: AppRoutesWithChildrenProps[] = [
  { path: "sync/", index: true, element: <SyncDebugging /> },
];

const purchaseRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "",
    index: true,
    element: <PurchaseList />,
    pageType: "Purchases",
    pageId: "Purchases",
  },
  {
    path: ":batchId",
    element: <PurchaseDetail />,
    pageType: "Purchases",
    pageId: "PurchaseDetail",
  },
];

const accountRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "",
    index: true,
    element: <Account />,
    pageType: "Accounts",
    pageId: "Home",
  },
  {
    path: "edit",
    element: <AccountEdit />,
    pageType: "Accounts",
    pageId: "Edit",
  },
];

const dtcAccountRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "",
    index: true,
    element: <DtcAccount />,
    pageType: "Accounts",
    pageId: "Home",
  },
  {
    path: "edit",
    element: <AccountEdit />,
    pageType: "Accounts",
    pageId: "Edit",
  },
];

const rewardsRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "balance",
    element: <CashOut />,
    pageType: "RewardsBalance",
    pageId: "Balance",
  },
  {
    path: "activity",
    index: true,
    element: <Activity />,
    pageType: "RewardsActivity",
    pageId: "Activity",
  },
  {
    path: "expiring",
    element: <Expiring />,
    pageType: "RewardsExpiring",
    pageId: "Expiring",
  },
  {
    path: "payouts/",
    children: [
      {
        path: "",
        element: <Payouts />,
        pageType: "RewardsPayouts",
        pageId: "Home",
      },
      {
        path: ":transactionId",
        element: <PayoutDetail />,
        pageType: "RewardsPayouts",
        pageId: "PayoutDetail",
      },
    ],
  },
  { path: "", element: <Navigate to="balance" /> },
  { path: "*", element: <Navigate to="balance" /> },
];

const dtcRewardsRoutesChildroutes: AppRoutesWithChildrenProps[] = [
  {
    path: "balance",
    element: <CashOut />,
    pageType: "RewardsBalance",
    pageId: "Balance",
  },
  {
    path: "activity",
    index: true,
    element: <Activity />,
    pageType: "RewardsActivity",
    pageId: "Activity",
  },
  {
    path: "expiring",
    element: <Expiring />,
    pageType: "RewardsExpiring",
    pageId: "Expiring",
  },
  {
    path: "payouts/",
    children: [
      {
        path: "",
        element: <Payouts />,
        pageType: "RewardsPayouts",
        pageId: "Home",
      },
      {
        path: ":transactionId",
        element: <PayoutDetail />,
        pageType: "RewardsPayouts",
        pageId: "PayoutDetail",
      },
    ],
  },
  { path: "", element: <Navigate to="balance" /> },
  { path: "*", element: <Navigate to="balance" /> },
];

const dtcRewardsRoute: AppRoutesWithChildrenProps[] = [
  {
    path: "",
    element: <DtcRewardsOutlet />,
    children: dtcRewardsRoutesChildroutes,
  },
];

const dashboardChildRoutes: AppRoutesWithChildrenProps[] = [
  { path: "", element: <Navigate to="me" /> },
  {
    path: "me",
    index: true,
    element: <Dashboard />,
    pageType: "Dashboard",
    pageId: "Home",
  },
  {
    path: "purchase/",
    children: purchaseRoutes,
    pageType: "Purchases",
    pageId: "Purchases",
  },
  {
    path: "accounts/",
    children: accountRoutes,
    pageType: "Accounts",
    pageId: "Home",
  },
  {
    path: "rewards/",
    children: rewardsRoutes,
    pageType: "Rewards",
    pageId: "Home",
  },
  {
    path: "profile",
    element: <Profile />,
    pageType: "Personal",
    pageId: "Personal",
  },
  { path: "address/", children: dashboardAddressRoutes },
  { path: "wallet/", children: dashboardWalletRoutes },
  { path: "debugging/", children: dashboardDebuggingRoutes },
];

const dtcDashboardChildRoutes: AppRoutesWithChildrenProps[] = [
  { path: "", element: <Navigate to="me" /> },
  {
    path: "me",
    index: true,
    element: <Dashboard />,
    pageType: "Dashboard",
    pageId: "Home",
  },
  { path: "purchase/", children: purchaseRoutes },
  { path: "accounts/", children: dtcAccountRoutes },
  {
    path: "rewards",
    children: dtcRewardsRoute,
  },
  {
    path: "profile",
    element: <Profile />,
    pageType: "Personal",
    pageId: "Personal",
  },
];

export const dashboardRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "dashboard/",
    element: <DashboardOutlet />,
    children: dashboardChildRoutes,
  },
];

export const dtcDashboardRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "/",
    element: <DtcDashboardOutlet />,
    children: dtcDashboardChildRoutes,
    handle: {
      pageNavConfig: {
        hasViewBagButton: true,
      },
    },
  },
];
