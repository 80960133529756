import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  Operation,
  ServerError,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import {
  getJwt,
  getUserSignedInState,
  getVisitorIdCookie,
  signOut,
} from "../Auth";
import ConfigUtils from "../utils/ConfigUtils";
import {
  tryGetVisitorId,
  AnalyticsService,
  AnalyticsTrackingEvent,
} from "@brandclub/common-ui";
import { getCommonRequestHeaders } from "../utils/axios";

const httpLink = createHttpLink({
  uri: (operation) => {
    //hits api-rewards endpoint
    return `${ConfigUtils.getGraphQlUri()}?id=${operation.operationName}`;
  },
  headers: getCommonRequestHeaders(),
});

const httpLinkServerSideSync = createHttpLink({
  //hits retailer-sync endpoint
  uri: ConfigUtils.getServerSideSyncGraphQlUri(),
  headers: getCommonRequestHeaders(),
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const jwt = await getJwt();
  const additionalHeaders: any = {};
  if (jwt) {
    additionalHeaders["Authorization"] = `Bearer ${jwt}`;
  }
  const uniqueVisitorId = await tryGetVisitorId();
  const uniqueVisitorIdFromCookie = getVisitorIdCookie();
  const uniqueVisitorIdToUse =
    uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
      ? uniqueVisitorIdFromCookie
      : uniqueVisitorId;
  if (uniqueVisitorIdToUse) {
    additionalHeaders["x-bc-visitor-id"] = uniqueVisitorIdToUse;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...additionalHeaders,
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation }: ErrorResponse) => {
    const operationName = operation.operationName
      ? " " + operation.operationName
      : "";
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL ERROR]:${operationName} Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.error(`[Network ERROR]:${operationName}`, networkError);
    }
    AnalyticsService.track(AnalyticsTrackingEvent.GRAPHQL_ERROR, {
      message: networkError?.message,
      operationName: operation.operationName,
      statusCode: (networkError as ServerError)?.statusCode,
    });
    if (
      networkError &&
      networkError.name === "ServerError" &&
      (networkError as ServerError).statusCode === 401
    ) {
      console.warn("Signing user out due to 401");
      try {
        getUserSignedInState().then(
          ({ signedIn, userInfo }) => {
            if (userInfo && signedIn) {
              signOut();
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } catch (e) {}
    }
  }
);

const retryLink = new RetryLink({
  delay: (count, _operation) => {
    const wait = count * 1000 * Math.random();
    if (count > 1) {
      console.warn(
        "GraphQLRetry #",
        count,
        _operation.operationName,
        "after",
        wait,
        "ms"
      );
    }
    return wait;
  },
  attempts: (count, _operation: Operation, error: any) => {
    if (count > 5 || !error) return false;

    const message = error?.message;
    return (
      typeof message === "string" && message.includes("Network request failed")
    );
  },
});

const apolloClient = new ApolloClient({
  // httpLink here hits api-rewards endpoint
  link: from([errorLink, authLink, retryLink, httpLink]),
  cache: new InMemoryCache(),
  headers: getCommonRequestHeaders(),
});

/**
 * @deprecated 
 * Use apollo client from the apollo provider instead.
 * Simply replace the URI in context with ConfigUtils.getServerSideSyncGraphQlUri()
 */
export const serverSideSyncApolloClient = new ApolloClient({
  link: from([errorLink, authLink, retryLink, httpLinkServerSideSync]),
  cache: new InMemoryCache(),
  headers: getCommonRequestHeaders(),
});

export default apolloClient;
