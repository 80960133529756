import { styled, useMediaQuery, useTheme } from "@mui/material";
import _get from "lodash/get";
import moment from "moment";
import { RetailerWithSyncStatus } from "../../../../../../redux/types";
import {
  formatMoney,
  getContrastPrimaryColor,
  getEntityImage,
} from "../../../../../../utils/StringUtils";
import { MOBILE_SCREEN_SIZE } from "../../../../../AppNavigation/constants";
import {
  ButtonLoadingEllipsis,
  SolidButton,
} from "../../../../../StoreComponents/StoreButton";
import ConnectRetailerDialog from "../../../../../StoreComponents/StoreDialogs/ConnectRetailerDialog";
import DisconnectRetailerDialog from "../../../../../StoreComponents/StoreDialogs/DisconnectRetailerDialog";
import { useSyncContext } from "../../../../../SyncProvider";

import { CheckCircleIcon } from "@brandclub/common-ui";
import { syncRetailerServerSide } from "../../../../../../api";
import { useSiteContext } from "../../../../../SiteProvider";
import useStoreType from "../../../../../../utils/hooks/useStoreType";
import { StoreBrandingType } from "../../../../../../types/misc";
import { useNavigate } from "react-router-dom";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

const RetailerCardContainer = styled("div")(({ theme }) => ({
  padding: "27px 15px 21px 15px",
  borderRadius: 10,
  border: "solid 1px #dcdddc",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 17,
  [`@media screen and (max-width: ${1250}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 10,
    padding: "20.9px 15px",
    maxWidth: 260,
  },
  ".logo": {
    width: `46%`,
    maxHeight: 80,
    maxWidth: 80,
    aspectRatio: "1/1",
    objectFit: "contain",
  },
  ".name": {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${1250}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
  ".button": {},
  ".status": {
    fontSize: 14,
    textAlign: "center",
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${1250}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
}));

const RetailerCardButton = styled(SolidButton, {
  shouldForwardProp: (prop) => prop !== "variant",
})<{ variant?: "success" | "fix" | "sync" | "connect" }>(
  ({ theme, variant }) => {
    let backgroundColor: string | undefined;
    let color: string | undefined;
    let borderColor: string | undefined;
    switch (variant) {
      case "success":
      case "sync":
        backgroundColor = "#ffffff";
        color = theme.palette.primary.main;
        break;
      case "connect":
        backgroundColor = "#f6f8fa";
        color = theme.palette.primary.main;
        borderColor = "#f6f8fa";
        break;
      default:
        // use whatever is defined in SolidButton
        backgroundColor = undefined;
        color = undefined;
        borderColor = undefined;
        break;
    }
    return {
      backgroundColor,
      color,
      borderColor,
      width: 141.5,
      height: 38.5,
      fontSize: 14,
      fontWeight: 600,
      minWidth: 108,
      [`@media screen and (max-width: ${1250}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        height: 30,
        width: "auto",
        fontSize: 12,
        minWidth: 88,
      },
    };
  }
);

const RetailerCard = ({
  retailer,
  disconnect,
}: {
  retailer: RetailerWithSyncStatus;
  disconnect?: boolean;
}) => {
  const theme = useTheme();
  const storeType = useStoreType();
  const isDtc = storeType === StoreBrandingType.CustomDTCStore;
  const { retailerConnections } = useSyncContext();
  const { signedIn } = useSignedIn();
  const navigate = useNavigate();
  const { abTestingTreatments } = useSiteContext();
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const rewardAmount = retailer.retailerConnectRewardAmount ?? 0;
  let lastScanTime = _get(
    retailerConnections,
    [`${retailer.parentRetailerId || retailer.retailerId}`, "lastSyncTime"],
    null
  );
  const treatment = abTestingTreatments.syncOptionForRetailerTreatment;

  const scanText = !lastScanTime
    ? ""
    : moment(lastScanTime).isAfter(moment().add(-1, "d"))
    ? `Synced ${moment(lastScanTime).fromNow()}`
    : `Synced on ${moment(lastScanTime).format("MMM D")}`;

  const handleConnect = () => {
    if (isDtc) {
      navigate("/connect", { state: { retailerId: retailer.retailerId } });
    } else {
      ConnectRetailerDialog.show(retailer.retailerId);
    }
  };

  const statusText =
    retailer.currentSyncStatus === "syncing"
      ? "Syncing"
      : retailer.connectionStatus === "new" && rewardAmount > 0
      ? `to earn ${formatMoney(rewardAmount)}`
      : retailer.connectionStatus === "invalid"
      ? "Disconnected"
      : scanText;

  return (
    <RetailerCardContainer>
      <img
        alt="logo"
        className="logo"
        src={getEntityImage(retailer.retailerId, "retailer")}
      ></img>
      <div className="name">{retailer.displayName}</div>
      <div className="button">
        {disconnect ? (
          <RetailerCardButton
            variant="success"
            onClick={() => {
              DisconnectRetailerDialog.show(retailer);
            }}
          >
            Disconnect
          </RetailerCardButton>
        ) : (
          <>
            {retailer.connectionStatus === "valid" && (
              <RetailerCardButton
                variant="success"
                disabled={retailer.currentSyncStatus !== "none"}
              >
                {retailer.currentSyncStatus === "syncing" ? (
                  <>
                    Syncing
                    <ButtonLoadingEllipsis
                      baseFontSize={mobileView ? 17 : 12}
                      blue
                    />
                  </>
                ) : (
                  <>
                    <CheckCircleIcon
                      backgroundColor={theme.palette.secondary.main}
                      htmlColor={getContrastPrimaryColor(
                        theme.palette.secondary.main,
                        theme
                      )}
                      sx={{
                        marginRight: "8px",
                        maxWidth: 17,
                        maxHeight: 17,
                        width: "100%",
                        height: "100%",
                        padding: "2px",
                      }}
                    />
                    Success
                  </>
                )}
              </RetailerCardButton>
            )}
            {retailer.connectionStatus === "invalid" && (
              <RetailerCardButton
                variant="fix"
                onClick={() => {
                  handleConnect();
                }}
                disabled={retailer.currentSyncStatus !== "none"}
              >
                {retailer.currentSyncStatus === "syncing" ? (
                  <>
                    Syncing
                    <ButtonLoadingEllipsis
                      baseFontSize={mobileView ? 17 : 12}
                    />
                  </>
                ) : (
                  "Fix"
                )}
              </RetailerCardButton>
            )}
            {retailer.connectionStatus === "new" && (
              <RetailerCardButton
                variant="connect"
                onClick={() => {
                  if (signedIn) {
                    handleConnect();
                  } else {
                    navigate("/signin");
                  }
                }}
                disabled={retailer.currentSyncStatus !== "none"}
              >
                {retailer.currentSyncStatus === "syncing" ? (
                  <>
                    Syncing
                    <ButtonLoadingEllipsis
                      baseFontSize={mobileView ? 17 : 12}
                      blue
                    />
                  </>
                ) : (
                  "Connect"
                )}
              </RetailerCardButton>
            )}
          </>
        )}
        {treatment === "on" && (
          <>
            <RetailerCardButton
              variant="sync"
              onClick={() => {
                syncRetailerServerSide({
                  req: {
                    parentRetailerIds: [
                      retailer.parentRetailerId || retailer.retailerId,
                    ],
                  },
                });
              }}
            >
              Sync
            </RetailerCardButton>
          </>
        )}
      </div>
      <div className="status">{statusText}</div>
    </RetailerCardContainer>
  );
};

export default RetailerCard;
