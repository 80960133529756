import { styled } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import AuthAnimateBackground from "./components/AuthAnimateBackground";

export const AuthSignUpPageContainer = styled("div")(({ theme }) => ({
  minHeight: "100%",
  height: "100%",
  width: "100%",
  justifyContent: "flex-start",
  flex: 1,
  alignItems: "center",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  ".auth_container": {
    display: "flex",
    flexDirection: "column",
    maxWidth: 420,
    width: "100%",
    boxSizing: "border-box",
    padding: "50px 20px 50px 20px",
    minHeight: "100%",
    alignItems: "stretch",
    justifyContent: "flex-start",
    "> *": {
      marginLeft: "auto",
      marginRight: "auto",
    },
    ".page_title": {
      fontSize: 24,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
      marginBottom: 9,
    },
    ".page_subtitle": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    ".reward_summary": {
      marginTop: 30,
      fontSize: 14,
      textAlign: "center",
      fontWeight: 500,
      maxWidth: 255,
      color: theme.palette.primary.main,
    },
    ".page_content": {
      marginTop: 30,
      marginBottom: 30,
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    ".resend": {
      marginTop: 30,
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.primary.main,
      ".click": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    ".cash_earned": {
      ".label": {},
      ".amount": {},
    },
    ".retailer_logo": {
      maxHeight: 110,
      width: 210,
      maxWidth: "100%",
      objectFit: "contain",
    },
    ".terms": {
      marginTop: 25,
      fontSize: 12,
      color: "#000",
      opacity: 0.3,
      textAlign: "center",
      a: {
        color: "#000",
        textDecoration: "underline",
      },
    },
  },
}));

const AuthSignUpPageOutlet = ({ animated }: { animated?: boolean }) => {
  const location = useLocation();
  const onSuccess = location.pathname?.toLowerCase().includes("success");
  const onSignin = location.pathname?.toLowerCase().includes("signin");
  const onSsoSign = location.pathname?.toLowerCase().includes("sso");
  const hideAnimation = onSuccess || onSignin || onSsoSign;

  return (
    <>
      {hideAnimation || !animated ? null : <AuthAnimateBackground />}
      <AuthSignUpPageContainer className="authSignUpPage">
        <Outlet />
      </AuthSignUpPageContainer>
    </>
  );
};

export default AuthSignUpPageOutlet;
